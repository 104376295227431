import React, {
  useEffect, createContext, useState, useContext,
} from 'react';
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  useMediaQuery,
  LinearProgress,
  Tooltip,
  Fade,
  IconButton, RadioGroup, Switch, FormControlLabel,
} from '@mui/material';
import dynamic from 'next/dynamic';
import Badge from '@mui/material/Badge';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { HelpOutline } from '@mui/icons-material';
import { parametrics } from '../lib/dao';
import { MyContext } from './_app';
import { captureException } from '@sentry/nextjs';

const SearchBar = dynamic(() => import('../src/components/SearchBar'));
const AllFilters = dynamic(() => import('../src/components/AllFilters'));
const SearchCompanyResults = dynamic(() => import('../src/components/SearchCompanyResults'));
const SearchPersonResults = dynamic(() => import('../src/components/SearchPersonResults'));
const RadioButtons = dynamic(() => import('../src/components/RadioButtons'));

const reCaptchaV3Key = process.env.NEXT_PUBLIC_RECAPTCHA_V3_KEY;
export const filterOptions = createContext();

export async function getServerSideProps(context) {
  const lang = context.req.cookies['next-i18next'];
  const data = await parametrics(lang ?? 'el');
  return {
    props: {
      data,
    },
  };
}

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Home(props) {
  const phone = useMediaQuery('(min-width:600px)');
  const { language } = useContext(MyContext);
  const [load, setLoad] = useState(false);
  const [searched, setSearched] = useState(false);
  const [companyTabFlag, setCompanyTabFlag] = useState(false);
  const [personTabFlag, setPersonTabFlag] = useState(false);
  const [activeFilters, setactiveFilters] = useState(0);
  const [newLangLoading, setNewLangLoading] = useState(false);
  const { t } = useTranslation();
  const { data } = props;
  data.payload = { ...data.payload, suspension: [{ id: true, descr: t('Ναι') }, { id: false, descr: t('Όχι') }] };
  const [page, setPage] = useState({
    companyPage: 1,
    personsPage: 1,
  });
  const { places } = data.payload;
  const placesFormattedData = places.length > 0 ? JSON.parse(places): [];
  const [companyPages, setCompanyPages] = useState(1);
  const [personPagination, setPersonPagination] = useState(1);
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({});
  const [companyLoading, setCompanyLoading] = useState(false);
  const [personLoading, setPersonLoading] = useState(false);
  const dict = {
    legalType: t('Νομική Μορφή'),
    status: t('Κατάσταση'),
    suspension: t('Σε Αναστολή Καταχώρησης'),
    specialCharacteristics: t('Ειδικοί Χαρακτηρισμοί'),
    armodiaGEMI: t('Αρμόδια Τοπική Υπηρεσία Γ.Ε.ΜΗ.'),
    kad: t('Δραστηριότητα'),
    places: t('Περιοχή'),
    city: t('Πόλη'),
    postcode: t('ΤΚ'),
    recommendationDateFrom: t('Ημερομηνία Σύστασης'),
    recommendationDateTo: t('Ημερομηνία Σύστασης'),
    closingDateFrom: t('Ημ/νία Κλεισίματος'),
    closingDateTo: t('Ημ/νία Κλεισίματος'),
    alterationDateFrom: t('Ημ/νία Μεταβολής ΚΑΚ'),
    alterationDateTo: t('Ημ/νία Μεταβολής ΚΑΚ'),
  };

  const initialFilterValues = {
    q: null,
    inputField: null,
    city: null,
    postcode: null,
    legalTypeData: data.payload.legalType,
    legalType: [],
    statusData: data.payload.status,
    status: [],
    suspensionData: data.payload.suspension,
    suspension: [],
    categoryData: data.payload.category,
    category: [],
    specialCharacteristicsData: data.payload.specialCharacteristics,
    specialCharacteristics: [],
    employeeNumberData: data.payload.employeeNumber,
    employeeNumber: [],
    armodiaGEMIData: data.payload.armodiaGEMI,
    armodiaGEMI: [],
    kad: [],
    kadData: [],
    recommendationDateFrom: null,
    recommendationDateTo: null,
    closingDateFrom: null,
    closingDateTo: null,
    alterationDateFrom: null,
    alterationDateTo: null,
    personData: data.payload.person,
    person: [],
    personrecommendationDateFrom: null,
    personrecommendationDateTo: null,
    radioValue: 'all',
    places: [],
    placesData: placesFormattedData,
  };
  const [formData, setFormData] = useState(initialFilterValues);
  const [searchResultsCompanies, setSearchResultsCompanies] = useState([]);
  const [searchResultsPersons, setSearchResultsPersons] = useState([]);

  // flag to not run the language useEffect on load
  const [langFirstLoadFlag, setLangFirstLoadFlag] = useState(false);

  useEffect(async () => {
    if (typeof language !== 'undefined' && langFirstLoadFlag) {
      setNewLangLoading(true);
      const newParametrics = await axios.post('/api/getNewParametrics', {
        language,
      });
      setFormData({
        ...formData,
        kadData: JSON.parse(newParametrics.data.payload.kads),
        legalTypeData: newParametrics.data.payload.legalType,
        statusData: newParametrics.data.payload.status,
        personData: newParametrics.data.payload.person,
        specialCharacteristicsData:
          newParametrics.data.payload.specialCharacteristics,
        armodiaGEMIData: newParametrics.data.payload.armodiaGEMI,
        placesData: JSON.parse(newParametrics.data.payload.places),
      });
    }
    setNewLangLoading(false);
    setLangFirstLoadFlag(true);
  }, [language]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const myStorage = window.sessionStorage;
      if (typeof myStorage.filterValues !== 'undefined') {
        setFormData({
          ...formData,
          ...JSON.parse(myStorage.filterValues),
        });
        setPaginationFilters(JSON.parse(myStorage.filterValues));
      } else {
        setFormData(initialFilterValues);
        myStorage.filterValues = JSON.stringify({
          inputField: initialFilterValues.inputField,
          city: initialFilterValues.city,
          postcode: initialFilterValues.postcode,
          legalType: initialFilterValues.legalType,
          status: initialFilterValues.status,
          suspension: initialFilterValues.suspension,
          category: initialFilterValues.category,
          specialCharacteristics: initialFilterValues.specialCharacteristics,
          employeeNumber: initialFilterValues.employeeNumber,
          armodiaGEMI: initialFilterValues.armodiaGEMI,
          kad: initialFilterValues.kad,
          recommendationDateFrom: initialFilterValues.recommendationDateFrom,
          recommendationDateTo: initialFilterValues.recommendationDateTo,
          closingDateFrom: initialFilterValues.closingDateFrom,
          closingDateTo: initialFilterValues.closingDateTo,
          alterationDateFrom: initialFilterValues.alterationDateFrom,
          alterationDateTo: initialFilterValues.alterationDateTo,
          person: initialFilterValues.person,
          personrecommendationDateFrom:
            initialFilterValues.personrecommendationDateFrom,
          personrecommendationDateTo:
            initialFilterValues.personrecommendationDateTo,
          radioValue: initialFilterValues.radioValue,
          places: initialFilterValues.places,
        });
      }

      if (
        typeof myStorage.companySearchResults !== 'undefined'
        && JSON.parse(myStorage.companySearchResults).length !== 0
        && JSON.parse(myStorage.companySearchResults) !== 'undefined'
      ) {
        setSearched(true);
        setCompanyTabFlag(true);
        setSearchResultsCompanies(JSON.parse(myStorage.companySearchResults));
        setCompanyPages(myStorage.companyPages);
        setPage({
          companyPage: parseInt(myStorage.currentCompanyPage, 10) ?? 1,
          personsPage: parseInt(myStorage.currentPersonPage, 10) ?? 1,
        });
        setValue(
          typeof myStorage.activeTab !== 'undefined'
            ? parseInt(myStorage.activeTab, 10)
            : 0,
        );
      }

      if (
        typeof myStorage.personSearchResults !== 'undefined'
        && JSON.parse(myStorage.personSearchResults).length !== 0
        && JSON.parse(myStorage.personSearchResults) !== 'undefined'
      ) {
        setSearched(true);
        setPersonTabFlag(true);
        setSearchResultsPersons(JSON.parse(myStorage.personSearchResults));
        setPersonPagination(myStorage.personPages);
        setPage({
          companyPage: parseInt(myStorage.currentCompanyPage, 10) ?? 1,
          personsPage: parseInt(myStorage.currentPersonPage, 10) ?? 1,
        });
        setValue(
          typeof myStorage.activeTab !== 'undefined'
            ? parseInt(myStorage.activeTab, 10)
            : 0,
        );
      }
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (typeof window !== 'undefined') {
      const myStorage = window.sessionStorage;
      myStorage.activeTab = JSON.stringify(newValue);
    }
  };

  async function handleCompanyPaginationSearch(companyPage, token) {
    setCompanyLoading(true);
    const dataToBeSent = {
      inputField: paginationFilters.inputField,
      city: paginationFilters.city,
      postcode: paginationFilters.postcode,
      legalType: paginationFilters.legalType,
      status: paginationFilters.status,
      suspension: paginationFilters.suspension,
      category: paginationFilters.category,
      specialCharacteristics: paginationFilters.specialCharacteristics,
      employeeNumber: paginationFilters.employeeNumber,
      armodiaGEMI: paginationFilters.armodiaGEMI,
      kad: paginationFilters.kad,
      recommendationDateFrom: paginationFilters.recommendationDateFrom,
      recommendationDateTo: paginationFilters.recommendationDateTo,
      closingDateFrom: paginationFilters.closingDateFrom,
      closingDateTo: paginationFilters.closingDateTo,
      alterationDateFrom: paginationFilters.alterationDateFrom,
      alterationDateTo: paginationFilters.alterationDateTo,
      person: paginationFilters.person,
      personrecommendationDateFrom:
        paginationFilters.personrecommendationDateFrom,
      personrecommendationDateTo: paginationFilters.personrecommendationDateTo,
      radioValue: paginationFilters.radioValue,
      places: paginationFilters.places,
      page: companyPage,
    };
    axios
      .post('api/searchCompany', { dataToBeSent, token, language })
      .then((response) => {
        setSearchResultsCompanies(response.data.hits);
        setCompanyLoading(false);
        if (typeof window !== 'undefined') {
          const myStorage = window.sessionStorage;
          myStorage.companySearchResults = JSON.stringify(response.data.hits);
          myStorage.currentCompanyPage = companyPage;
        }
      })
      .catch(e => {
        console.error(e);
        console.trace(e);
        captureException(e);
      });
  }

  async function handlePersonPaginationSearch(personPage, token) {
    setPersonLoading(true);
    const dataToBeSent = {
      inputField: paginationFilters.inputField,
      city: paginationFilters.city,
      postcode: paginationFilters.postcode,
      legalType: paginationFilters.legalType,
      status: paginationFilters.status,
      suspension: paginationFilters.suspension,
      category: paginationFilters.category,
      specialCharacteristics: paginationFilters.specialCharacteristics,
      employeeNumber: paginationFilters.employeeNumber,
      armodiaGEMI: paginationFilters.armodiaGEMI,
      kad: paginationFilters.kad,
      recommendationDateFrom: paginationFilters.recommendationDateFrom,
      recommendationDateTo: paginationFilters.recommendationDateTo,
      closingDateFrom: paginationFilters.closingDateFrom,
      closingDateTo: paginationFilters.closingDateTo,
      alterationDateFrom: paginationFilters.alterationDateFrom,
      alterationDateTo: paginationFilters.alterationDateTo,
      person: paginationFilters.person,
      personrecommendationDateFrom:
        paginationFilters.personrecommendationDateFrom,
      personrecommendationDateTo: paginationFilters.personrecommendationDateTo,
      radioValue: paginationFilters.radioValue,
      places: paginationFilters.places,
      page: personPage,
    };
    axios
      .post('api/searchPerson', { dataToBeSent, token, language })
      .then((response) => {
        setSearchResultsPersons(response.data.hits);
        setPersonLoading(false);
        if (typeof window !== 'undefined') {
          const myStorage = window.sessionStorage;
          myStorage.personSearchResults = JSON.stringify(response.data.hits);
          myStorage.currentPersonPage = personPage;
        }
      })
      .catch((e) => {
        console.error(e);
        console.trace(e);
        captureException(e);
      });
  }

  async function handleSearchClick(contextToTrim, token) {
    const dataToBeSent = {
      inputField: contextToTrim.inputField,
      city: contextToTrim.city,
      postcode: contextToTrim.postcode,
      legalType: contextToTrim.legalType,
      status: contextToTrim.status,
      suspension: contextToTrim.suspension,
      category: contextToTrim.category,
      specialCharacteristics: contextToTrim.specialCharacteristics,
      employeeNumber: contextToTrim.employeeNumber,
      armodiaGEMI: contextToTrim.armodiaGEMI,
      kad: contextToTrim.kad,
      recommendationDateFrom: contextToTrim.recommendationDateFrom,
      recommendationDateTo: contextToTrim.recommendationDateTo,
      closingDateFrom: contextToTrim.closingDateFrom,
      closingDateTo: contextToTrim.closingDateTo,
      alterationDateFrom: contextToTrim.alterationDateFrom,
      alterationDateTo: contextToTrim.alterationDateTo,
      person: contextToTrim.person,
      personrecommendationDateFrom: contextToTrim.personrecommendationDateFrom,
      personrecommendationDateTo: contextToTrim.personrecommendationDateTo,
      radioValue: contextToTrim.radioValue,
      places: contextToTrim.places,
    };
    dataToBeSent.inputField = dataToBeSent.inputField?.trim();
    setFormData({ ...formData, inputField: dataToBeSent.inputField });
    let hasEnteredFilter = false;
    for (const key in dataToBeSent) {
      if (
        dataToBeSent[key] !== dataToBeSent.radioValue
        && dataToBeSent[key]?.length !== 0
        && dataToBeSent[key] !== null
        && dataToBeSent[key] !== undefined
      ) {
        hasEnteredFilter = true;
        break;
      }
    }
    let hasChangedFilters = false;
    if (typeof window !== 'undefined') {
      const storedFilters = JSON.parse(sessionStorage.filterValues ?? null);
      if (typeof storedFilters !== 'undefined' && storedFilters !== null) {
        for (const key in dataToBeSent) {
          if (
            JSON.stringify(dataToBeSent[key])
            !== JSON.stringify(storedFilters[key])
          ) {
            if (
              key === 'inputField'
              && dataToBeSent[key] === undefined
              && storedFilters[key] === null
            ) {
            } else {
              hasChangedFilters = true;
              break;
            }
          }
        }
      }
    }

    setPaginationFilters({ ...dataToBeSent });

    if (
      dataToBeSent.inputField !== '000000000'
      && ((dataToBeSent.inputField?.length !== 0
        && dataToBeSent.inputField?.length !== undefined
        && dataToBeSent.inputField?.length !== null)
        || hasEnteredFilter)
      && hasChangedFilters
    ) {
      if (typeof window !== 'undefined') {
        const myStorage = window.sessionStorage;
        myStorage.filterValues = JSON.stringify(dataToBeSent);
        myStorage.currentCompanyPage = '1';
        myStorage.currentPersonPage = '1';
      }
      setPage({ personsPage: 1, companyPage: 1 });
      setExpanded(false);

      setLoad(true);
      axios
        .post('api/search', { dataToBeSent, token, language })
        .then((response) => {
          setSearched(true);
          setLoad(false);
          setSearchResultsCompanies(response.data.company?.hits);
          setCompanyPages(response.data.company?.total.value);
          setSearchResultsPersons(response.data.person?.hits);
          setPersonPagination(response.data.person?.total.value);
          setValue(0);
          if (typeof window !== 'undefined') {
            const myStorage = window.sessionStorage;
            myStorage.companySearchResults = JSON.stringify(
              response.data.company?.hits,
            );
            myStorage.companyPages = response.data.company?.total.value <= 500
              ? response.data.company?.total.value
              : 501;

            myStorage.personSearchResults = JSON.stringify(
              response.data.person?.hits,
            );
            myStorage.personPages = response.data.person?.total.value <= 500
              ? response.data.person?.total.value
              : 501;
          }
        })
        .catch(e => {
          console.error(e);
          console.trace(e);
          captureException(e);
        });
    }
  }
  async function search() {
    if(process.env.NEXT_PUBLIC_RECAPTCHA_V3_AVAILABLE==='true') {
      const { grecaptcha } = window;
      grecaptcha.ready(async () => {
        const recaptchaAction = { action: 'search' };
        grecaptcha.execute(reCaptchaV3Key, recaptchaAction)
          .then((token) => {
            handleSearchClick(formData, token);
          });
      });
    } else await handleSearchClick(formData, null);
  }
  async function searchPerson(pagePerson) {
    if(process.env.NEXT_PUBLIC_RECAPTCHA_V3_AVAILABLE==='true') {
      const { grecaptcha } = window;
      grecaptcha.ready(async () => {
        const recaptchaAction = { action: 'searchPersonPage' };
        grecaptcha.execute(reCaptchaV3Key, recaptchaAction)
          .then((token) => {
            handlePersonPaginationSearch(pagePerson, token);
          });
      });
    } else await handlePersonPaginationSearch(pagePerson, null);
  }
  async function searchCompany(pageCompany) {
    if(process.env.NEXT_PUBLIC_RECAPTCHA_V3_AVAILABLE==='true') {
      const { grecaptcha } = window;
      grecaptcha.ready(async () => {
        const recaptchaAction = { action: 'searchCompanyPage' };
        grecaptcha.execute(reCaptchaV3Key, recaptchaAction)
          .then((token) => {
            handleCompanyPaginationSearch(pageCompany, token);
          });
      });
    } else await handleCompanyPaginationSearch(pageCompany, null);
  }

  const [selectedFilterStringCompany, setSelectedFilterStringCompany] = useState();
  const [selectedFilterStringPerson, setSelectedFilterStringPerson] = useState();
  function onlyUnique(currValue, index, self) {
    return self.indexOf(currValue) === index;
  }
  useEffect(() => {
    let listOfFiltersCompany = [];
    let listOfFiltersPerson = [];
    for (const property in formData) {
      if (
        formData[property] !== null
        && formData[property] !== ''
        && formData[property]?.length !== 0
        && !property.includes('Data')
        && property !== 'inputField'
        && property !== 'radioValue'
      ) {
        if (
          // Person filter chips
          [
            'personrecommendationDateFrom',
            'personrecommendationDateTo',
            'person',
          ].includes(property)
        ) {
          if (property === 'person') {
            listOfFiltersPerson.push(t('Συσχέτιση με επιχείρηση'));
          } else listOfFiltersPerson.push(t('Ημ/νία Συσχέτισης'));
        } else {
          // Company filter chips
          listOfFiltersCompany.push(dict[property]);
        }
      }
    }
    listOfFiltersCompany = listOfFiltersCompany.filter(onlyUnique);
    listOfFiltersPerson = listOfFiltersPerson.filter(onlyUnique);
    setSelectedFilterStringPerson(listOfFiltersPerson);
    setSelectedFilterStringCompany(listOfFiltersCompany);
    setactiveFilters(listOfFiltersPerson.length + listOfFiltersCompany.length);
  }, [formData]);
  return (
    <div>
      <Container maxWidth="lg" style={!phone ? { paddingLeft: 0, paddingRight: 0, minHeight: '85vh' } : { minHeight: '85vh' }}>
        <Box
          display="flex"
          justifyContent="center"
          textAlign="center"
          m={1}
          p={1}
        >
          {phone ? <h1>{t('Αναζήτηση Στοιχείων Δημοσιότητας')}</h1> : <h4>{t('Αναζήτηση Στοιχείων Δημοσιότητας')}</h4>}
        </Box>
        <filterOptions.Provider
          value={{
            data: formData,
            setData: setFormData,
            searchResultsPersons,
            searchResultsCompanies,
            pageState: { page, setPage },
            companyPages,
            personPagination,
            setCompanyPages,
            setPersonPagination,
            searchFunction: search,
            searchCompany,
            searchPerson,
            companyLoading,
            setCompanyLoading,
            personLoading,
            setPersonLoading,
            newLangLoading,
          }}
        >
          <Container style={phone ? { } : { marginTop: '-35px' }}>
            <p style={phone ? { fontWeight: 600 } : { fontSize: '12px' }}>
              {t('Στην παρακάτω φόρμα μπορείτε να πληκτρολογήσετε ΑΦΜ, Αριθμό ΓΕΜΗ, Επωνυμία, Διακριτικό τίτλο ή μέρος αυτών.')}
            </p>
            {phone ? (<p style={{ fontWeight: 350 }}>
              {t('Για πιο σύνθετες αναζητήσεις χρησιμοποιήστε τα')}
              <Button color="primary" onClick={() => setExpanded(true)}>
                {t('Φιλτρα')}
              </Button>
            </p>) : null}
            <Box display="flex" flexWrap={phone ? 'nowrap' : 'wrap'}>
              <SearchBar data-test="SearchBar" />
              {!phone && (<IconButton
                style={{ padding: 0 }}
                variant="text"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  search();
                }}
              >
                <Tooltip title={t('Αναζήτηση')} arrow>
                  <SearchIcon sx={{ fontSize: '9vw' }} />
                </Tooltip>
              </IconButton>
              )}
              {phone && (
              <Box
                display="flex"
                flexDirection="row"
                marginLeft={phone ? '' : '20%'}
              >
                <IconButton
                  variant="text"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    search();
                  }}
                >
                  <Tooltip title={t('Αναζήτηση')} arrow>
                    <SearchIcon fontSize="large" />
                  </Tooltip>
                </IconButton>
                {expanded === true ? (
                  <Button
                    color="primary"
                    onClick={() => {
                      setExpanded(false);
                    }}
                  >
                    <CloseIcon fontSize="large" />
                  </Button>
                ) : (
                  <Badge
                    overlap="rectangular"
                    badgeContent={activeFilters}
                    max={999}
                    color="secondary"
                  >
                    <Button color="primary" onClick={() => setExpanded(true)}>
                      {t('Φιλτρα')}
                    </Button>
                  </Badge>
                )}
              </Box>
              )}
            </Box>
            <Box
            >
              <RadioButtons id="1" />
            </Box>
            {!phone && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="right"
              >
                {expanded === true ? (
                  <Badge
                    overlap="rectangular"
                    badgeContent={activeFilters}
                    max={999}
                    color="secondary"
                    sx={{ '& .MuiBadge-badge': { right: '45px' } }}
                  >
                    <FormControlLabel
                      control={
                        <Switch checked={true} onChange={() => setExpanded(false)} name="filtersOn" />
                      }
                      label={<Typography color="primary.light" variant="button">{t('Φιλτρα')}</Typography>}
                      labelPlacement="start"
                    />
                  </Badge>
                ) : (
                  <Badge
                    overlap="rectangular"
                    badgeContent={activeFilters}
                    max={999}
                    color="secondary"
                    sx={{ '& .MuiBadge-badge': { right: '45px' } }}
                  >
                    <FormControlLabel
                      control={
                        <Switch checked={false} onChange={() => setExpanded(true)} name="filtersOff" />
                      }
                      label={<Typography color="text.disabled" variant="button">{t('Φιλτρα')}</Typography>}
                      labelPlacement="start"
                    />
                  </Badge>
                )}
              </Box>
            )}
            {load ? (
              <Box mt={1} mb={1}>
                <LinearProgress />
              </Box>
            ) : null}
            <Box display="flex" flexWrap="nowrap">
              <div style={{ display: !expanded && 'none' }}>
                <AllFilters
                  data={{
                    selectedFilterStringCompany,
                    selectedFilterStringPerson,
                    search,
                  }}
                />
              </div>
            </Box>
            {searched && !load && (
              <Box>
                <div>
                  <Card
                    style={{
                      marginBottom: 5,
                      marginTop: 5,
                      width: 'inherit',
                    }}
                  >
                    <CardContent>
                      <Typography gutterBottom align="left" variant={phone ? "h6": "subtitle1"}>
                        {t('Aποτελέσματα Αναζήτησης')}
                      </Typography>
                      {(process.env.NEXT_PUBLIC_PERSON_FILTERS === 'true' && searchResultsPersons?.length !== 0)
                      || searchResultsCompanies?.length !== 0 ? (
                        <>
                          <Tabs
                            value={value}
                            data-test={value}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                          >
                            {searchResultsCompanies.length !== 0 && (
                              <Tab
                                label={`${t('ΕΠΙΧΕΙΡΗΣΕΙΣ')} (${
                                  companyPages > 100 ? '100+' : companyPages
                                })`}
                                {...a11yProps(0)}
                              />
                            )}
                            {searchResultsPersons.length !== 0 && process.env.NEXT_PUBLIC_PERSON_FILTERS === 'true' && (
                              <Tab
                                label={`${t('ΦΥΣΙΚΑ ΠΡΟΣΩΠΑ')} (${
                                  personPagination <= 100
                                    ? personPagination
                                    : '100+'
                                })`}
                                {...a11yProps(
                                  personTabFlag && !companyTabFlag ? 0 : 1,
                                )}
                              />
                            )}
                          </Tabs>
                          {searchResultsCompanies.length !== 0 && (
                            <TabPanel value={value} index={0}>
                              <SearchCompanyResults />
                            </TabPanel>
                          )}
                          {searchResultsPersons.length !== 0 && process.env.NEXT_PUBLIC_PERSON_FILTERS === 'true' && (
                            <TabPanel
                              value={value}
                              index={
                                searchResultsPersons.length !== 0
                                && searchResultsCompanies.length === 0
                                  ? 0
                                  : 1
                              }
                            >
                              <SearchPersonResults />
                            </TabPanel>
                          )}
                        </>
                        ) : (
                          <Box
                            style={{
                              display: 'flex',
                              marginTop: '10%',
                              marginBottom: '10%',
                              alignItems: 'center',
                              justifyContent: 'center',
                              alignText: 'center',
                            }}
                          >
                            <Typography color="primary" variant={phone ? "h6": "subtitle1"}>
                              {t('Δεν βρέθηκαν αποτελέσματα...')}
                            </Typography>
                          </Box>
                        )}
                    </CardContent>
                  </Card>
                </div>
              </Box>
            )}
          </Container>
        </filterOptions.Provider>
      </Container>
    </div>
  );
}
